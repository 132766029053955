// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\idaho\\source\\repos\\whoisidaho\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("C:\\Users\\idaho\\source\\repos\\whoisidaho\\src\\pages\\404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("C:\\Users\\idaho\\source\\repos\\whoisidaho\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("C:\\Users\\idaho\\source\\repos\\whoisidaho\\src\\pages\\page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\idaho\\source\\repos\\whoisidaho\\.cache\\data.json")

